import type {
  IContacts,
} from '@/types';

interface IFormattedPhone {
  raw: string
  formatted: string
}

export function useContactPhones(phones: IContacts['phones']): IFormattedPhone[] {
  return phones.map(({ phone }) => {
    const rawPhone = phone
      .replace(/[^\d]+/g, '')
      .replace(/^[7-8](.+)/, '$1');
    const formattedPhone = rawPhone.replace(/([\d]{3})([\d]{3})([\d]{2})([\d]{2})/, '+7 ($1) $2-$3-$4');

    return {
      raw: rawPhone,
      formatted: formattedPhone,
    };
  });
}
